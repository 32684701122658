$accent: #82b844;
$dark-accent: #72a637;

.button {
  padding: 14px 30px 16px 30px;
  background: #fff;
  border-radius: 10px;

  width: fit-content;

  font-size: 16px;
  font-weight: 500;

  transition: all 100ms linear;

  border: 1px solid #cad3e0;

  color: #53627a;
  margin: 10px 0;
  cursor: pointer;

  &:disabled {
    cursor: default;
    color: #a0abbd !important;
    border-color: transparent !important;
  }

  &.vk {
    &:hover {
      border-color: #03a5fc;
      color: #03a5fc;
    }
  }

  &.google {
    &:hover {
      border-color: rgb(80, 134, 236);
      color: rgb(80, 134, 236);
    }
  }

  &.yandex {
    &:hover {
      border-color: rgb(233, 79, 50);
      color: rgb(233, 79, 50);
    }
  }

  &.primary {
    border: none;
    background: $accent;
    color: white;
    &:hover {
      background: $dark-accent;
    }
  }

  &.secondary {
    &:hover {
      border-color: $accent;
      color: $accent;
    }
  }

  &.href {
    border: none;
    color: $accent;
    &:hover {
      color: $dark-accent;
    }
  }

  &.link {
    border: none;
    padding-left: 10px;
    padding-right: 10px;
    color: #999;

    &:after {
      display: block;
      position: relative;
      content: " ";
      top: 0px;
      border-bottom: 1px solid transparent;
      transition: all 100ms linear;
    }
    &:hover {
      color: $accent;
      &:after {
        top: 14px;
        border-bottom-color: $accent;
      }
    }
  }
}

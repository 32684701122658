.text {
  font-size: 18px;
  color: #53627a;
  line-height: 180%;
  width: 100%;
  margin: 10px 0;
}

@media (max-width: 1000px) {
  .text {
    font-size: 16px;
    line-height: 200%;
  }
}

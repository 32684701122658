.text {
  font-size: 20px;
  color: #444;
  line-height: 180%;
}

@media (max-width: 1000px) {
  .text {
    font-size: 16px;
    line-height: 200%;
  }
}

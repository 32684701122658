$error: #eb5050;
$accent: #82b844;

.container {
  position: fixed;
  right: 20px;
  bottom: 20px;
  max-width: 400px;

  padding: 10px 20px 12px 20px;
  line-height: 170%;
  border-radius: 10px;
  font-weight: normal;
  font-size: 16px;
  width: 100%;
  margin: 10px 0;

  background-color: white;

  transition: all 200ms linear;

  cursor: default;

  color: $error;
  box-shadow: 0 0 60px rgba($color: #000000, $alpha: 0.05);

  &:empty {
    display: none;
  }

  &:hover {
    bottom: 22px;
    box-shadow: 0 0 60px rgba($color: #000000, $alpha: 0.1);
  }

  &.warning {
    color: $error;
    border-color: $error;
  }

  &.success {
    color: $accent;
    border-color: $accent;
  }
}

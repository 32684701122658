$accent: #82b844;
$warning: #eb5050;

.input {
  font-size: 16px;
  margin: 12px 0;
  padding: 10px 20px 12px 20px;
  border-radius: 10px;

  outline: none;
  border: 1px solid #cad3e0;

  width: 100%;

  transition: border 200ms linear;

  &:focus,
  &:hover,
  &:active {
    border-color: $accent;
  }

  &.warning {
    transition: 0ms;

    border-color: $warning !important;
  }
}

.container {
  position: relative;
  width: 100%;
  max-width: 400px;
}
.warningText {
  top: 0;
  left: 20px;
  background-color: #fff;
  padding: 2px;
  color: $warning;
  position: absolute;
}

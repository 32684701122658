$error: #eb5050;
$accent: #82b844;

.container {
  padding: 10px 20px 12px 20px;
  line-height: 170%;
  border-radius: 10px;
  font-weight: normal;
  font-size: 16px;
  width: 100%;
  margin: 10px 0;

  color: $error;
  border: 1px solid $error;

  &.warning {
    color: $error;
    border-color: $error;
  }

  &.success {
    color: $accent;
    border-color: $accent;
  }
}

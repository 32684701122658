.container {
  margin: auto;
  // width: fit-content;
  display: flex;
  flex-direction: column;

  margin-top: 150px;

  width: 100%;
  padding: 0 20px;
  max-width: 400px;

  // height: 80vh;
  justify-content: center;
  align-items: center;
}

@media (max-height: 800px) {
  .container {
    margin-top: 50px;
  }
}

@media (max-height: 700px) {
  .container {
    margin-top: 0px;
  }
}

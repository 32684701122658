.container {
  display: flex;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 20px;
  border-bottom: 1px solid #cad3e0;
}

.href {
  display: block;
  padding: 20px 30px;
  font-size: 18px;
  font-weight: bold;
  color: #afb7c4;
}

.active {
  color: #53627a;
}

$error: #eb5050;
$accent: #82b844;

.container {
  line-height: 170%;
  border-radius: 10px;
  font-weight: normal;
  font-size: 16px;
  position: relative;
}

.status {
  margin-right: 10px;
  color: #333;

  font-size: 13px;
}

.statusText {
  font-size: 13px;
  color: #aaa;
}

.message {
  color: #333;
}

.close {
  position: absolute;
  right: -15px;
  top: -10px;
  color: #aaa;
  font-size: 24px;
  cursor: pointer;

  transition: all 200ms linear;

  &:active {
    transform: scale(0.8);
  }

  &:hover {
    color: #333;
  }
}

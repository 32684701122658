.container {
  position: fixed;
  right: 0;
  top: 0;
  padding: 20px;
  // background-color: #cad3e0;
  a {
    height: 40px;
    width: 40px;
    line-height: 40px;
    font-size: 72px;
    display: block;
    color: #afb7c4;
    transition: all 100ms linear;
    &:hover {
      color: #53627a;
    }
  }
}

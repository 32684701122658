@font-face {
  font-family: "Rubik";
  src: url("./assets/fonts/Rubik-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Rubik";
  src: url("./assets/fonts/Rubik-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Rubik";
  src: url("./assets/fonts/Rubik-SemiBold.ttf");
  font-weight: 600;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Rubik";
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.leaflet-control-attribution {
  display: none;
}

#paramaterValueContainer {
  position: relative;
  border-left: 2px solid #0009;
  border-radius: 10px 0 0 0;
  height: 100px;
  top: -100px;
}
#paramaterValueContainer::after {
  display: block;

  position: relative;
  top: 30px;
  left: -5px;
  content: " ";

  border-radius: 1000px;

  background-color: white;

  height: 8px;
  width: 8px;
}

#ParamaterValue {
  width: max-content;
  padding: 10px 20px 14px 16px;
  background: #0005;
  font-weight: 500;
  color: white;
  font-size: 14px;
  border-radius: 8px 10px 10px 0;
  pointer-events: painted;

  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);

  height: 69px;
  min-width: 50px;
}

@supports not (-webkit-backdrop-filter: blur(10px)) {
  #ParamaterValue {
    background-color: #0009;
  }
}

.parameter-label {
  font-size: 12px;
  font-weight: 400;
}
.parameter-value {
  font-size: 18px;
}

.parameter-close {
  position: absolute;

  right: 5px;
  top: 5px;
}

.meteostation {
  z-index: 99999;
}

.css-yk16xz-control,
.css-1pahdxg-control {
  background-color: #0003 !important;
  border-color: transparent !important;

  &:focus,
  &:active {
  }
  * {
    color: white !important;
  }
}

.css-1wy0on6 {
  display: none !important;
}

select::-ms-expand {
  display: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.icon {
  width: 20px;
  height: 20px;
  background: #fafafa;
  display: block;
  text-indent: -9999px;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.meteostationInfo {
  display: none;
  background: #fff;
  padding: 6px 10px 10px 10px;
  border-radius: 10px;
  color: #333;
  font-weight: 500;
  // position: absolute;
  width: max-content;

  & .name {
    margin: 0 -10px;
    z-index: 999;
    // margin-bottom: 5px;
    position: sticky;
    top: -6px;
    margin-top: -9px;
    border-radius: 10px 10px 0 0;
    padding: 10px 10px;
    background: #fff;
  }
}

.meteostation {
  z-index: 999 !important;
  .icon {
    background: #fff7;
    transition: background 100ms linear;
  }
  &:hover {
    .icon {
      background: #fff;
    }
  }

  &:focus,
  &:active {
    z-index: 9999 !important;
    .icon {
      background: #fff;
    }
    .meteostationInfo {
      display: block;
      max-height: 500px;
      overflow: auto;
    }
  }
  .loading-circle {
    margin-top: 10px;
  }
}

#playIcon {
  margin-right: 20px;
  &.dark {
    // display: none;
  }
}

#timeline {
  &.dark {
    background-color: #0003;
    padding: 20px 20px 30px 20px;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
  }
}
#logotype {
  position: relative;
  width: fit-content;

  &.dark {
    top: 40px;
    left: 35px;
    padding: 7px 10px 8px 10px;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
  }
}

#logotype.loading {
  &::after {
    content: " ";
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 1000px;
    border: 4px dashed white;

    top: 6px;
    left: 190px;
    animation: round 4000ms ease-in-out infinite;
  }
  &.dark::after {
    top: 14px;
    left: 206px;
  }
}

.loading-circle {
  content: " ";
  width: 20px;
  height: 20px;
  border-radius: 1000px;
  border: 4px dashed white;
  animation: round 4000ms ease-in-out infinite;
}

.loading-circle.black {
  border-color: #333;
}

@keyframes round {
  0% {
    transform: rotate(0deg);
    border-width: 4px;
  }

  // 20% {
  //   transform: rotate(240deg);
  // }

  50% {
    transform: rotate(360deg);
    border-width: 2px;
  }
  100% {
    transform: rotate(0deg);
    border-width: 4px;
  }
}

.meteostationInfo {
  font-size: 14px;
}

.meteo-info-block {
  font-size: 14px;
  line-height: 180%;

  .label {
    font-weight: 400;
  }
  & div {
    display: flex;
    align-items: center;
    margin-top: 10px;
    padding: 10px 20px;
    border-radius: 10px;
  }

  & div:nth-child(1) {
    margin-top: 20px;
    margin-bottom: 5px;
    font-size: 18px;

    margin: 0 -10px;

    // margin-bottom: 5px;
    position: sticky;
    top: 35px;
    padding: 10px 20px 10px 20px;
    background: #fff;
    color: #111;

    z-index: 998;
  }

  & div {
    position: sticky;
    top: 80px;
    margin: 0 -10px;

    background: #fff;
    color: #111;
  }
  & .label {
    width: 200px;
    display: inline-block;
  }
}

.meteostation {
  z-index: 999 !important;
}

#map,
.leaflet-pane,
.leaflet-marker-pane {
  z-index: initial !important;
}

.hidden {
  display: none !important;
}

.selectedHour {
  position: sticky;
  left: 0;
  right: 0;
}

.parameter-value-marker {
  z-index: 999 !important;
}

.map-overlay-dark {
  z-index: 0 !important;
  filter: invert(0.9);
}

// .map-overlay {
//   z-index: 0 !important;
//   filter: invert(0.9);
// }

.forecast-tile-layer {
  opacity: 1 !important;
}

.circle {
  z-index: 999;
  position: fixed;
  display: inline-block;
  border-radius: 50%;
  border: 1px solid #333;
  width: 30px;
  height: 30px;
  left: 30px;
  right: 0;
  transition: transform 0.2s ease, opacity 0.2s ease, border-color 0.2s ease,
    background-color 0.2s ease;
  pointer-events: none;
}

.parameter-tile-layer {
  // filter: blur(4px);
}

/* total width */
*::-webkit-scrollbar {
  background-color: #0000;
  width: 10px;
  padding: 4px;
}

/* background of the scrollbar except button or resizer */
*::-webkit-scrollbar-track {
  background-color: #0000;
}

/* scrollbar itself */
*::-webkit-scrollbar-thumb {
  background-color: #0005;

  border-radius: 16px;
  border: 4px solid #0000;
}

/* set button(top and bottom of the scrollbar) */
*::-webkit-scrollbar-button {
  display: none;
}

.parameter-tile-layer .leaflet-tile {
  // background-color: #666;
}

$logoback-width: 160px;
@media (max-width: 991px,) {
  #logotype.loading {
    &::after {
      left: 50% !important;
      margin-left: -10px !important;
      top: 50px !important;
    }
  }
  #logotype {
    &.dark {
      top: 20px;
      left: 0;
      right: 0;
      backdrop-filter: none;
      -webkit-backdrop-filter: none;
      padding: 12px 10px 12px 10px;
      z-index: 1;
      &::before {
        content: " ";
        width: $logoback-width;
        height: 52px;

        left: 50%;
        margin-left: -$logoback-width/2;
        top: 0;

        z-index: -1;
        position: absolute;

        border-radius: 10px;
        // background: #0003;
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
      }
    }
    &::after {
      left: 50% !important;
      margin-left: -10px !important;
      top: 50px !important;
    }
  }
  *::-webkit-scrollbar {
    display: none;
  }
}
